export default () => ({
    init() {
        this.observe();
    },
    observe() {
        let observer = new IntersectionObserver(
            entries =>
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.$wire.call('loadMoreResults');
                        observer.unobserve(this.$el);
                    }
                }),
            {
                root: null,
            }
        );

        observer.observe(this.$el);
    },
});
