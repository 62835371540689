export default () => ({
    continentsSelect: null,
    countriesSelect: null,
    filter: [],
    plus: '<path fill-rule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clip-rule="evenodd"></path>',
    minus: '<path fill-rule="evenodd" d="M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z" clip-rule="evenodd"></path>',

    init() {
        window.Livewire.on('product-search:updated', () => {
            this.$root.querySelectorAll('dialog').forEach(dialog => dialog.close());
            document.body.classList.remove('overflow-hidden');
            this.initContinents();
            this.initFilter();
        });

        this.initContinents();
        this.initFilter();
    },

    initContinents() {
        this.continentsSelect = this.$root.querySelector('select.continents-select');
        this.countriesSelect = this.$root.querySelector('select.countries-select');

        if (this.continentsSelect) {
            this.continentsSelect.addEventListener('change', event => {
                for (let i = 0; i < this.countriesSelect.options.length; i++) {
                    const option = this.countriesSelect.options[i];
                    const selected = [...event.target.selectedOptions];
                    option.disabled = selected.length > 0;
                    [...event.target.selectedOptions].map(continentOption => {
                        if (option.dataset.continents.includes(continentOption.value)) {
                            option.disabled = false;
                        }
                    });
                }
                this.$root.querySelector('.countries-select-trigger').dispatchEvent(new Event('change'));
            });
        }
    },

    initFilter() {
        this.$root.querySelectorAll('.product-search-filter').forEach(filter => {
            this.updateFilterSelected(filter);
            filter.querySelectorAll('input[type="checkbox"]')
                .forEach((checkbox, index) => {
                    checkbox.addEventListener(
                        'change',
                        event => {
                            this.updateFilterSelected(event.target.closest('.product-search-filter'));
                            if (checkbox.getAttribute('name') === 'hasFlight') {
                                this.setHasFlight(event);
                            }
                        }
                    );
                });
        });
    },

    updateFilterSelected(filter) {
        const selected = Array.from(filter.querySelectorAll('input[type="checkbox"]:checked')).map(elem => elem.closest('label').querySelector('.text').innerText.trim());
        filter.querySelector('.selected').innerHTML = selected.join(', ');
        if (selected.length) {
            filter.querySelector('.active').classList.remove('hidden');
        } else {
            filter.querySelector('.active').classList.add('hidden');
        }
    },

    setHasFlight(event) {
        const selected = Array.from(event.target.closest('.product-search-filter')
            .querySelectorAll('input[type="checkbox"][name="hasFlight"]:checked')).map(elem => elem.value);
        if (selected.length === 1) {
            this.$wire.set('hasFlight', selected[0], true);
        } else {
            this.$wire.set('hasFlight', null, true);
        }
    }
});
